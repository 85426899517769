<div fxLayout="column" class="photo-container">
  <h2 class="section-title text-shadow" style="margin-top: 0px;">Nuestras fotos</h2>
  <div style="padding:20px">
    <mat-grid-list cols="2" rowHeight="2:1" [gutterSize]="'10px'">
      <!--prettier-ignore-->
      <mat-grid-tile *ngFor="let photo of photos;" class="photo" [ngStyle]="{'background-image':'url('+ photo.url +')'}" (click)="onclickPhoto(photo)">
        <mat-grid-tile-footer *ngIf="photo.caption !== ''">{{photo.caption}}</mat-grid-tile-footer>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
