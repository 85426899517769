import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Video } from 'src/app/models/video';
import { VideosService } from './videos.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css'],
})
export class VideosComponent implements OnInit, OnDestroy {
  videos: Video[];
  windowWidth: number;
  constructor(private readonly videosService: VideosService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.videos = this.videosService.getAll().sort((a, b) => {
      if (a.principal > b.principal) {
        return -1;
      } else if (a.principal < b.principal) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  ngOnDestroy() {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;
  }

  showVideo(video: Video) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '80vw',
      height: '60vh',
      data: video,
    });

    dialogRef.afterClosed().subscribe();
  }
}
