<div fxLayout="column" style="background-color: black">
  <h2 class="section-title text-shadow" style="margin-top: 0px">Nuestros Videos</h2>
  <!-- prettier-ignore -->
  <div [class.video-background-auto]="video.bgAuto" class="video-container" *ngFor="let video of videos;"  fxLayoutGap="30px" [ngStyle]="{'background-image': 'url(' + video.videoThumb + ')'}" fxLayout="column" fxLayoutAlign="center center">
        <h2 class="video-title">{{video.title}}</h2>
        <a mat-raised-button style="cursor:pointer; background-color: #f3dc96;"  (click)="showVideo(video)">
            <i class="fa fa-film fa-2x video-ico"></i>
        </a>
        <h3 class="video-subtitle text-shadow">{{video.subtitle}}</h3>
    </div>
</div>
