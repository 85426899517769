import { Component, OnInit } from '@angular/core';
import { Photo } from 'src/app/models/photo';
import { ImageViewerService } from '../image-viewer/image-viewer.service';
import { PhotosService } from './photos.service';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css'],
})
export class PhotosComponent implements OnInit {
  photos: Photo[];
  constructor(private readonly photosService: PhotosService, private readonly imageViewerService: ImageViewerService) {}

  ngOnInit(): void {
    this.photos = this.photosService.getAll();
  }

  onclickPhoto(photo: Photo): void {
    this.imageViewerService.imageUrl = photo.url;
    this.imageViewerService.show();
  }
}
