import { Injectable } from '@angular/core';
import { Photo } from 'src/app/models/photo';

@Injectable({
  providedIn: 'root',
})
export class PhotosService {
  private photos: Photo[] = [
    { caption: 'Primeros ensayos', url: 'assets/images/gallery/big-4.jpg' },
    { caption: 'Con esto comenzó todo', url: 'assets/images/gallery/big-2.jpg' },
    { caption: 'In the Box', url: 'assets/images/gallery/big-5.jpg' },
    { caption: 'Estresados en FloresFest', url: 'assets/images/gallery/big-6.jpg' },
    { caption: 'Super-Estresados', url: 'assets/images/gallery/big-7.jpg' },
    { caption: 'FloresFest - Antonio Gil', url: 'assets/images/gallery/big-8.jpg' },
    { caption: 'FloresFest - Raquel Lao', url: 'assets/images/gallery/big-9.jpg' },
    { caption: 'FloresFest - Ana Rojas', url: 'assets/images/gallery/big-10.jpg' },
    { caption: 'FloresFest - Macarena Durán', url: 'assets/images/gallery/big-20.jpg' },
    { caption: 'FloresFest - Mariluz Montesinos', url: 'assets/images/gallery/big-11.jpg' },
    { caption: 'Cambio de local', url: 'assets/images/gallery/big-1.jpg' },
    { caption: '!!Unnn tom!!', url: 'assets/images/gallery/big-12.jpg' },
    { caption: 'Super friends', url: 'assets/images/gallery/big-13.jpg' },
    { caption: '❄ nieve ❄', url: 'assets/images/gallery/big-18.jpg' },
    { caption: '❄ más nieve ❄', url: 'assets/images/gallery/big-19.jpg' },
  ];

  constructor() {}

  getAll() {
    return this.photos;
  }
}
