import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { YouTubePlayerModule } from '@angular/youtube-player';

import { DialogComponent } from './dialog.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, YouTubePlayerModule, FlexModule, MatButtonModule],
  declarations: [DialogComponent],
  exports: [DialogComponent],
  //   entryComponents: [DialogComponent],
})
export class DialogModule {}
