import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageViewerService } from './image-viewer.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent {

  constructor(public readonly imageViewerService: ImageViewerService) { }


  onClickClose() {
    this.imageViewerService.hide();
  }

}
