import { Injectable } from '@angular/core';
import { Video } from 'src/app/models/video';

@Injectable({
  providedIn: 'root',
})
export class VideosService {
  constructor() {}

  videosData: Video[] = [
    {
      videoUrl: 'https://www.youtube.com/watch?v=cy1OvQ2rDhc',
      videoThumb: 'https://img.youtube.com/vi/cy1OvQ2rDhc/hqdefault.jpg',
      principal: true,
      title: `Flores Fest' 19`,
      subtitle: `by Alberto Alvarado Film`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=xigE4B32ptk',
      videoThumb: 'https://img.youtube.com/vi/xigE4B32ptk/maxresdefault.jpg',
      principal: false,
      title: `Your speaking my language`,
      subtitle: `Cicus Rock '19`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=DowdAJeuB6M',
      videoThumb: 'https://img.youtube.com/vi/DowdAJeuB6M/hqdefault.jpg',
      principal: false,
      title: `FloresFest '19 Mix`,
      subtitle: `By SlowProject`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=PqrrPkq9n9I',
      videoThumb: 'https://img.youtube.com/vi/PqrrPkq9n9I/maxresdefault.jpg',
      principal: false,
      title: `Hey Sah-Lo-Ney`,
      subtitle: `Cicus Rock '19`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=Xv_Yd-l9uP0',
      videoThumb: 'https://img.youtube.com/vi/Xv_Yd-l9uP0/maxresdefault.jpg',
      principal: false,
      title: `7 Nation Army`,
      subtitle: `COVID-19`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=kl8ewNJ-Ck0',
      videoThumb: 'https://img.youtube.com/vi/kl8ewNJ-Ck0/maxresdefault.jpg',
      principal: false,
      title: `Hey Sah-Lo-Ney`,
      subtitle: `COVID-19`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=i1qISO4f-ZU',
      videoThumb: 'https://img.youtube.com/vi/i1qISO4f-ZU/maxresdefault.jpg',
      principal: false,
      title: `Sex on fire`,
      subtitle: `Maketa 2021`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=uHWrLJXKyO0',
      videoThumb: 'assets/images/gallery/big-24.jpg',
      principal: false,
      title: `Run`,
      subtitle: `Maketa 2021`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=4b4ZHLkt4Gk',
      videoThumb: 'assets/images/gallery/big-22.jpg',
      principal: false,
      title: `Celebrity skin`,
      subtitle: `Maketa 2021`,
    },
    {
      videoUrl: 'https://www.youtube.com/watch?v=wwx4Ly7tY7M',
      videoThumb: 'assets/images/gallery/big-23.jpg',
      principal: false,
      title: `Never tear us apart`,
      subtitle: `Maketa 2021`,
    },
  ];

  getAll() {
    return this.videosData;
  }
}
