import { Component, OnInit, Inject, Sanitizer, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Video } from 'src/app/models/video';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent implements OnInit {
  youtubeVideoId: string;

  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public video: Video) {}
  ngOnInit(): void {
    this.youtubeVideoId = this.video.videoUrl.substr(this.video.videoUrl.length - 11, 11);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {

  }
  onCloseClick(): void {
    this.dialogRef.close();
  }
}
