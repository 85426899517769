import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageViewerService {

  public visible: boolean = false;
  public imageUrl: string = '';

  constructor() { }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }
}
