<app-image-viewer></app-image-viewer>

<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <div fxFlex fxFill>
    <app-header></app-header>
  </div>
  <div fxFlex fxFill>
    <app-videos></app-videos>
  </div>
  <div fxFlex fxFill>
    <app-photos></app-photos>
  </div>
  <div fxFlex fxFill>
    <app-footer></app-footer>
  </div>
</div>
