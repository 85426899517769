import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { PhotosComponent } from './photos/photos.component';
import { VideosComponent } from './videos/videos.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DialogComponent } from './dialog/dialog.component';
import { DialogModule } from './dialog/dialog.module';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { ImageViewerService } from './image-viewer/image-viewer.service';

@NgModule({
  imports: [CommonModule, FlexLayoutModule, MatGridListModule, MatDialogModule, MatButtonModule, DialogModule],
  declarations: [PhotosComponent, VideosComponent, HeaderComponent, FooterComponent, ImageViewerComponent],
  exports: [HeaderComponent, VideosComponent, FooterComponent, PhotosComponent, ImageViewerComponent],
  providers: [ImageViewerService],
  entryComponents: [DialogComponent],
})
export class ComponentsModule {}
